/* eslint-disable @typescript-eslint/camelcase */
class SignupRequest {
  public email: string;

  public password: string;

  public passwordConfirmation: string;

  public first_name: string;

  public lastName: string;

  public companyName: string;

  toJson() {
    return {
      email: this.email,
      password: this.password,
      first_name: this.first_name,
      last_name: this.lastName,
      company_title: this.companyName,
      password_confirm: this.passwordConfirmation,
    };
  }
}

export default SignupRequest;
